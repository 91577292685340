import { AutocompleteRow } from '@eg/elements/AutocompleteRow';
import { SelectRow } from '@eg/elements/SelectRow';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { TextValue, ValueList, ValueRanges } from 'rlv-common';
import { getErrorMessage } from '../../../../Helper';
import AemFragment from '../../../../components/AemFragment/AemFragment';
import Footer from '../../../../components/Footer';
import { Headline } from '../../../../components/Headline/Headline';
import InfoText from '../../../../components/InfoText/infoText';
import { EmploymentSelection } from '../../../../helpers/EmploymentSelection';
import Debouncer from '../../../../helpers/debouncer';
import { usePreviousAndTrackError } from '../../../../hooks/usePreviousAndTrackError/usePreviousAndTrackError';
import { NavigationAction } from '../../../../routing/StateMachineTypes';
import { TrackingUserAttributes } from '../../../../tracking/tracking.types';
import { AemFragmentCatalog } from '../../../../types/AemFragmentCatalog';
import { DataIdLivingConditionsPage } from '../../../../types/DataId';
import { RlvProfession } from '../../../../types/RlvProfession';
import { SharedSubmitCallBack } from '../../../../types/callbacks/sharedSubmitCallBack';
import { createLivingConditionsProfessionSchema } from '../../../../validation/LivingConditionsPage';
import './professionForm.css';

export interface ProfessionFormModel {
  employmentSelection: string | undefined;
  professionSelection: string | undefined;
  assignedProfessionKey: number | undefined;
}

export interface ProfessionFormProps extends SharedSubmitCallBack<ProfessionFormModel> {
  employment?: string;
  professionLabel?: string;
  professionKey?: number;
  getProfessions: ((term: string) => Promise<RlvProfession[]>) | undefined;
  valueRanges: ValueRanges;
  onNavigateNoSubmit: (navigationRequest: NavigationAction) => void;
  onSubmit: (selections: ProfessionFormModel, navigationRequest: NavigationAction) => void;
  updateTrackingValues: (attributes: Partial<TrackingUserAttributes>) => void;
}

export type SetValueFunction = (field: string, value: string) => void;

const ProfessionForm: React.FC<ProfessionFormProps> = ({
  employment,
  professionLabel,
  professionKey,
  getProfessions,
  valueRanges,
  onNavigateNoSubmit,
  onSubmit,
  updateTrackingValues,
}): React.ReactElement => {
  const { compareErrors } = usePreviousAndTrackError({ employment: '', professionLabel: '' });
  const [employmentSelection, setEmploymentSelection] = React.useState(employment);
  const [professionSelection, setProfessionSelection] = React.useState(professionLabel);
  const [assignedProfessionKey, setAssignedProfessionKey] = React.useState(professionKey);
  const [suggestions, setSuggestions] = React.useState([]);
  const [navigationRequest, setNavigationRequest] = React.useState(NavigationAction.NEXT);

  const headline = 'Geben Sie Ihre berufliche Situation an';
  const footerInfoSection = (
    <InfoText textBeforeLink="Wenn Sie jemand anders absichern möchten, geben Sie bitte dessen Beruf an."></InfoText>
  );

  const createOptions = (fieldName: string, values?: ValueList<TextValue>): JSX.Element[] => {
    const fieldOptions: JSX.Element[] = [];
    if (!values) {
      return [];
    }

    fieldOptions.push(
      <option key={`${fieldName}GroupDefault`} value="">
        Bitte wählen
      </option>,
    );
    if (values && values.possibleValues) {
      values.possibleValues.forEach((possibleValue: { key: string; text: string }) => {
        fieldOptions.push(
          <option key={`${fieldName}-${possibleValue.key}`} value={possibleValue.key}>
            {possibleValue.text}
          </option>,
        );
      });
    }
    return fieldOptions;
  };

  const debouncer: Debouncer = new Debouncer();
  const loadSuggestions = (term: string, setFieldValue: SetValueFunction) => {
    const minimalTermLength = 3;
    if (!term || term.length < minimalTermLength) {
      setSuggestions([]);
      return;
    }

    debouncer.debounce(async () => {
      if (getProfessions) {
        const loadedSuggestions = await getProfessions(term);
        setSuggestions(loadedSuggestions as []);
        if (suggestions && suggestions[term]) {
          setFieldValue(EmploymentSelection.PROFESSION_KEY, suggestions[term]);
        }
        setSuggestions(loadedSuggestions as []);
      }
    });
  };

  const getProfessionError = (
    form: FormikProps<object>,
    keyNames: string,
    labelName: string,
    valueLength: number = 0,
  ): undefined | string => {
    const error1 = getErrorMessage(form, { name: keyNames });
    const error2 = getErrorMessage(form, { name: labelName });
    if (valueLength > 3) {
      compareErrors('professionLabel', error1 || form.errors[keyNames]);
    }
    if (error1) {
      return error1;
    }
    if (error2) {
      return form.errors[keyNames];
    }
    return undefined;
  };

  return (
    <Formik
      initialValues={{
        employment,
        professionLabel,
        professionKey,
        valueRanges,
      }}
      onSubmit={() =>
        onSubmit(
          { employmentSelection, professionSelection, assignedProfessionKey },
          navigationRequest,
        )
      }
      validationSchema={createLivingConditionsProfessionSchema(valueRanges)}
    >
      {form => {
        const errorMessage = getProfessionError(
          form,
          EmploymentSelection.PROFESSION_KEY,
          EmploymentSelection.PROFESSION_LABEL,
          form.values.professionLabel?.length,
        );
        const errorsLength = Object.keys(form.errors).length || 0;
        const disableNext =
          errorsLength > 0 || employmentSelection === '' || professionSelection === '';

        return (
          <Form className="profession-form__body">
            <Headline text={headline} />
            <Field
              name={EmploymentSelection.EMPLOYMENT}
              render={({ field }: FieldProps<React.ChangeEvent<HTMLInputElement>>) => {
                compareErrors('employment', getErrorMessage(form, field));
                const { setFieldValue, setFieldTouched } = form;
                return (
                  <div className="profession-form__body--select-row">
                    <SelectRow
                      id={field.name}
                      name={field.name}
                      label="Beschäftigungsverhältnis"
                      data-component-id={DataIdLivingConditionsPage.INPUT_EMPLOYMENT}
                      onBlur={() => setFieldTouched(field.name)}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateTrackingValues({
                          occupationalGroup: event.target.value,
                        });
                        setFieldValue(field.name, event.target.value);
                        setEmploymentSelection(event.target.value);
                      }}
                      error={getErrorMessage(form, field)}
                      value={field.value}
                    >
                      {createOptions('employments', valueRanges.employments)}
                    </SelectRow>
                  </div>
                );
              }}
            />
            <Field
              name={EmploymentSelection.PROFESSION_LABEL}
              render={({ field }: FieldProps<React.ChangeEvent<HTMLInputElement>>) => {
                const { setFieldValue, setFieldTouched } = form;

                return (
                  <div className="profession-form__body--autocomplete">
                    <AutocompleteRow
                      id={field.name}
                      name={field.name}
                      label="Ausgeübter Beruf"
                      data-component-id={DataIdLivingConditionsPage.INPUT_PROFESSION}
                      onBlur={() => {
                        setFieldTouched(field.name);
                        setFieldTouched(EmploymentSelection.PROFESSION_KEY);
                      }}
                      onKeyUp={() => {
                        setFieldTouched(field.name);
                        setFieldTouched(EmploymentSelection.PROFESSION_KEY);
                      }}
                      onChange={(term: string) => {
                        setFieldValue(field.name, term);
                        updateTrackingValues({
                          jobTitle: term,
                        });
                        loadSuggestions(term, setFieldValue);
                        setFieldValue(EmploymentSelection.PROFESSION_KEY, suggestions[term]);
                        setProfessionSelection(term);
                        setAssignedProfessionKey(suggestions[term]);
                        if (suggestions.length === 1) {
                          setFieldValue(EmploymentSelection.PROFESSION_KEY, suggestions[0]);
                          setAssignedProfessionKey(suggestions[0]);
                        }
                      }}
                      error={errorMessage}
                      suggestions={Object.keys(suggestions)}
                      value={field.value}
                    />
                  </div>
                );
              }}
            />
            <AemFragment name={AemFragmentCatalog.STEP6VA} useVA={true} />
            <Footer
              showNext
              showPrevious
              labelNext={'weiter'}
              labelPrevious={'zurück'}
              infoSection={footerInfoSection}
              disableNext={disableNext}
              handleAction={(navigationAction: NavigationAction) => {
                setNavigationRequest(navigationAction);
                if (navigationAction === NavigationAction.BACK) {
                  onNavigateNoSubmit(navigationAction);
                  return;
                }
                form.submitForm();
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProfessionForm;
