import { Card } from '@eg/elements/Card';
import { AlertIcon } from '@eg/elements/components/Icons';
import React from 'react';
import { Nationality, RequestOfferPersonalDataModel, parseDate, KeweConsent } from 'rlv-common';
import { CorrectedAddress } from 'rlv-common/types/CorrectedAddress';
import { getIsAllPdePageDataValid } from 'src/helpers/personalDataPage';
import { mapKeweStatus } from 'src/tracking/trackingFormating';
import { isDivergingInsuredPerson, scrollToTop } from '../../Helper';
import AppLoader from '../../components/AppLoader';
import Footer, { AuxButton } from '../../components/Footer';
import { scrollToErrorByQuery } from '../../components/ScrollToError';
import { getServiceConfiguration } from '../../configuration/serviceConfiguration';
import { createScriptError } from '../../helpers/loadingScriptErrorHelper';
import { getKeweEnabled } from '../../helpers/modeConfig';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { DTMSeitenname } from '../../tracking/dtmTracking';
import { TrackingConversionTypes, TrackingElementBasic } from '../../tracking/tracking.types';
import { InjectedTrackerProps, withTracker } from '../../tracking/withTracker';
import { Configuration } from '../../types/Configuration';
import { PagePropsWithValues, StoreStateUpdater } from '../../types/PageProps';
import { PersonendatenInput } from '../../types/external/personendatenerfassung/messaging/model/input/PersonendatenInput';
import { VnInput } from '../../types/external/personendatenerfassung/messaging/model/input/VnInput';
import { VpInput } from '../../types/external/personendatenerfassung/messaging/model/input/VpInput';
import { AnfrageTyp } from '../../types/external/personendatenerfassung/messaging/model/input/AnfrageTyp';
import {
  FinishedPersonendatenOutput,
  PersonendatenOutput,
} from '../../types/external/personendatenerfassung/messaging/model/output/PersonendatenOutput';
import './RequestOfferPersonalDataPage.css';

export interface RequestOfferPageData
  extends RequestOfferPersonalDataModel,
    StoreStateUpdater<RequestOfferPageData> {
  oeNumber?: string;
  pageExitNotAllowed?: boolean;
  divergingInsuredPerson: boolean;
  nationalities?: Nationality[];
  showMeldung: boolean;
  keweConsent?: KeweConsent;
}

interface RequestOfferPersonalDataPageProps
  extends PagePropsWithValues<RequestOfferPageData>,
    InjectedTrackerProps {
  scriptLoaded: Promise<void>;
  configuration: Configuration;
  businessId: string;
  onError: (e: Error) => void;
}

interface RequestOfferPersonalDataPageState {
  isLoading: boolean;
  isEmailOptionalValid?: boolean;
  isEmailMandatoryValid?: boolean;
  showGlobalErrorMessages: boolean;
  isAddressInvalidInPds?: boolean;
  isAddressCorrectedInPds?: boolean;
  pdeEventRunning: boolean;
  pdeValid: boolean;
  enableNewPersonalDataFlow?: boolean;
  navigationAction: NavigationAction;
}

export class RequestOfferPersonalDataPage extends React.Component<
  RequestOfferPersonalDataPageProps,
  RequestOfferPersonalDataPageState
> {
  public constructor(props: RequestOfferPersonalDataPageProps) {
    super(props);
    this.state = {
      isLoading: false,
      showGlobalErrorMessages: false,
      isAddressInvalidInPds: false,
      pdeEventRunning: false,
      pdeValid: !!props.storeState.vn.pdeId,
      navigationAction: NavigationAction.NEXT,
      enableNewPersonalDataFlow: true, // hardcoded by the app
    };
    this.setStateHandler = this.setStateHandler.bind(this);
  }

  public setStateHandler(
    newState: Partial<RequestOfferPersonalDataPageState>,
    callback?: () => void,
  ) {
    this.setState({ ...this.state, ...newState }, callback ? () => callback() : undefined);
  }

  public onError = (e: Error) => {
    this.props.onError(e);
  };

  public onInvalidAddress = (isAddressInvalid: boolean) => {
    this.setState({ isAddressInvalidInPds: isAddressInvalid });
    this.props.storeState.update({ pageExitNotAllowed: isAddressInvalid });
    scrollToErrorByQuery('[role="alert"]');
  };

  public onValidationFailed = () => {
    this.setState({
      pdeEventRunning: false,
      isLoading: false,
    });
  };

  public onCorrectedAddress = (correctedAddresses: CorrectedAddress[]) => {
    correctedAddresses.forEach(correctedAddress => {
      if (correctedAddress.pdeId === this.props.storeState.vn.pdeId) {
        this.props.storeState.update({
          vn: {
            ...this.props.storeState.vn,
            adresse: {
              strasse: correctedAddress.strasse,
              hausnummer: correctedAddress.hausnummer,
              plz: correctedAddress.plz,
              ort: correctedAddress.ort,
            },
          },
        });
      }
      if (
        this.props.storeState.vp.pdeId &&
        correctedAddress.pdeId === this.props.storeState.vp.pdeId
      ) {
        this.props.storeState.update({
          vp: {
            ...this.props.storeState.vp,
            adresse: {
              strasse: correctedAddress.strasse,
              hausnummer: correctedAddress.hausnummer,
              plz: correctedAddress.plz,
              ort: correctedAddress.ort,
            },
          },
        });
      }
    });
  };

  public onChangedPersonendaten = (output: PersonendatenOutput) => {
    this.setState({
      pdeValid: output.isAbschlussMoeglich,
      pdeEventRunning: false,
      isLoading: false,
    });
    this.props.storeState.update({
      vn: {
        ...output.vn,
        birthdate: this.props.storeState.vn.birthdate
          ? this.props.storeState.vn.birthdate
          : this.props.storeState.vp.birthdate,
        role: output.vn.role ? output.vn.role : '',
        pdeId: output.vn.id,
      },
    });
    if (this.props.storeState.divergingInsuredPerson && output.vps && output.vps.length > 0) {
      this.props.storeState.update({
        vp: {
          ...output.vps[0],
          birthdate: this.props.storeState.vp.birthdate,
          role: output.vps[0].role ? output.vps[0].role : '',
          pdeId: output.vps[0].id,
        },
      });
    }
  };

  public onFinishedPersonendaten = async (output: FinishedPersonendatenOutput) => {
    this.setState({
      pdeEventRunning: false,
      isLoading: false,
    });

    this.setState({
      isEmailOptionalValid: output.isEmailOptionalValid,
      isEmailMandatoryValid: output.isEmailMandatoryValid,
      isAddressCorrectedInPds: output.isAddressCorrected || false,
    });

    if (!this.state.enableNewPersonalDataFlow) {
      this.closePde();
      this.props.handleAction(NavigationAction.NEXT);
    } else if (!output.isAddressCorrected) {
      this.closePde();
      this.props.handleAction(this.state.navigationAction);
    }
  };

  public async componentDidMount() {
    scrollToTop();
    await this.initPde();
    this.props.updateDTMTracking({ seitenName: DTMSeitenname.PERSONENDATEN_ANGEBOT });
    this.props.tracker.trackPageLoad({
      conversionType: TrackingConversionTypes.SALE_FUNNEL_PERSONAL_DATA,
    });
  }

  public componentWillUnmount() {
    // Wenn die PDE an dieser Stelle noch da ist, dann unmounten
    if (document.getElementById('root-personendaten')) {
      this.closePde();
    }
  }

  public render() {
    const cardLabel = isDivergingInsuredPerson() ? '' : 'Versicherte Person';

    return (
      <div className="personal-data-page" data-component-id="RequestOfferPage">
        <AppLoader show={this.state.isLoading} viewport="relative">
          <h3>Personendatenerfassung</h3>
          <br />
          <div>
            <Card label={cardLabel} accentColor="#737373">
              <div id="root-personendaten" />
            </Card>
            <div id="annotation-text" />
            {this.renderShowGlobalErrorMessages()}
            {this.renderFooter()}
          </div>
        </AppLoader>
      </div>
    );
  }

  private async initPde() {
    await this.props.scriptLoaded
      .then(() => {
        if (!this.props.configuration || !this.props.configuration.theme) {
          throw new Error(
            'The RequestOfferPersonalDataPage needs at least a theme! Check your configuration!',
          );
        }
        this.renderPDE();
      })
      .catch((error: Event) => {
        this.props.onError(createScriptError(error));
      });
  }

  public renderPDE() {
    document.dispatchEvent(
      new CustomEvent('renderPersonendaten', {
        detail: {
          callbacks: {
            onChangedPersonendaten: this.onChangedPersonendaten,
            onError: this.onError,
            onFinishedPersonendaten: this.onFinishedPersonendaten,
            onCorrectedAddress: this.onCorrectedAddress,
            onInvalidAddress: this.onInvalidAddress,
            onValidationFailed: this.onValidationFailed,
          },
          theme: this.props.configuration.theme,
          apiRootUrl: getServiceConfiguration().pde.baseUrl,
          creditor: {
            name: '',
          },
          angebotsId: this.props.businessId,
          vn: this.createVn(this.props.storeState),
          vps: this.createVPs(this.props.storeState),
          enableNewPersonalDataFlow: this.state.enableNewPersonalDataFlow, // hardcoded by the app
          enableKEweConsent: getKeweEnabled(), // from AEM
          hinweisBezugsrecht: false,
          disableEwe: true,
          addressValidation: true,
        } as PersonendatenInput,
      }),
    );
  }

  private closePde() {
    document.dispatchEvent(new CustomEvent('unmountPersonendaten', {}));
    this.setState({
      showGlobalErrorMessages: false,
      pdeEventRunning: false,
    });
    scrollToErrorByQuery('[data-component-id="vp-view-card"]');
  }

  private createVn(userInput: RequestOfferPageData): VnInput {
    return {
      ...userInput.vn,
      id: userInput.vn.pdeId,
      geburtsdatum: this.props.storeState.divergingInsuredPerson
        ? parseDate(userInput.vn.birthdate)
        : parseDate(userInput.vp.birthdate),
      role: userInput.vn.role,
      isEMailOptional: false,
      showGeburtsort: true,
      showEmailConfirmationTooltip: false,
      showStaatsangehoerigkeit: true,
      geschlecht: userInput.vn.geschlecht,
      isSexReadOnly: Boolean(userInput.vn.geschlecht),
    };
  }

  private createVPs(userInput: RequestOfferPageData): VpInput[] {
    const result: VpInput[] = [];
    if (this.props.storeState.divergingInsuredPerson) {
      result.push({
        ...userInput.vp,
        id: userInput.vp.pdeId,
        role: userInput.vp.role,
        isGWGRequired: true,
        geburtsdatum: parseDate(userInput.vp.birthdate),
        isSexReadOnly: Boolean(userInput.vp.geschlecht),
      });
    }
    return result;
  }

  private getErrorMessages(): string[] {
    const errorMessages: string[] = [];
    // if (!this.state.isEmailMandatoryValid) {
    //   errorMessages.push('Bitte geben Sie ein gültige E-mail address ein.');
    // }
    return errorMessages;
  }

  private renderShowGlobalErrorMessages() {
    // not in use, evaluate to remove it, analyze this ticket: KL-99291
    return (
      this.state.showGlobalErrorMessages &&
      !!this.getErrorMessages().length && (
        <div style={{ color: '#8e0038', fontSize: '14px' }}>
          <AlertIcon width={24} height={24} style={{ position: 'relative', top: '5px' }} /> Bitte
          beheben Sie die Fehler beim Absenden des Angebots:
          <ul>
            {this.getErrorMessages().map(element => (
              <li key={element}>{element}</li>
            ))}
          </ul>
          <br />
        </div>
      )
    );
  }

  private isAllPdePageDataValid(): boolean {
    return this.getErrorMessages().length === 0;
  }

  private handleButtonClick(navigationAction: NavigationAction) {
    // TODO correctly propagate navigationAction to clickedElement for tracking
    this.trackPersonalData();
    this.setState({ navigationAction });
  }

  private saveOfferPersonsInMicrofrontend() {
    this.setState({
      pdeEventRunning: true,
      isLoading: true,
    });

    document.dispatchEvent(
      new CustomEvent('nextClicked', {
        detail: AnfrageTyp.ANGEBOT,
      }),
    );

    scrollToErrorByQuery('[role="alert"]');
  }

  private trackPersonalData() {
    const { vn } = this.props.storeState;
    const { tracker } = this.props;
    const keweConsent = vn.keweConsent;
    tracker.updateTariffOptions(mapKeweStatus(keweConsent));
    tracker.updateUserContactInfo(vn);
    tracker.trackClickEvent({
      clickedElement: TrackingElementBasic.BUTTON_NEXT,
    });
  }

  private renderFooter() {
    return (
      <Footer
        showNext={true}
        showPrevious={true}
        labelNext="Angebot per E-Mail"
        handleAction={this.props.handleAction}
        handleBack={() => {
          if (!this.state.isAddressInvalidInPds && this.isAllPdePageDataValid()) {
            this.saveOfferPersonsInMicrofrontend();
            this.props.handleAction(NavigationAction.BACK);
          }
        }}
        handleNext={() => {
          // TODO: implement Angebot per E-Mail button functionality // DIS-868

          if (
            !getIsAllPdePageDataValid(this.state.pdeValid, this.state.isEmailMandatoryValid) &&
            this.state.isAddressInvalidInPds
          ) {
            scrollToErrorByQuery('[role="alert"]');
            return;
          }
          this.saveOfferPersonsInMicrofrontend();
          this.handleButtonClick(NavigationAction.OFFER_WRITTEN_EMAIL);
        }}
        auxButtons={[
          <AuxButton
            label="Sofort-Download"
            buttonId="btn1"
            key="1"
            onClick={() => {
              if (
                !getIsAllPdePageDataValid(this.state.pdeValid, this.state.isEmailMandatoryValid) &&
                this.state.isAddressInvalidInPds
              ) {
                scrollToErrorByQuery('[role="alert"]');
                return;
              }

              this.saveOfferPersonsInMicrofrontend();
              this.handleButtonClick(NavigationAction.OFFER_DIRECT_ONLINE);
            }}
          />,
          <AuxButton
            label="Angebot per Post"
            buttonId="btn2"
            key="2"
            onClick={() => {
              if (
                !getIsAllPdePageDataValid(this.state.pdeValid, this.state.isEmailMandatoryValid) &&
                this.state.isAddressInvalidInPds
              ) {
                scrollToErrorByQuery('[role="alert"]');
                return;
              }

              this.saveOfferPersonsInMicrofrontend();
              this.handleButtonClick(NavigationAction.OFFER_WRITTEN_POSTAL);
            }}
          />,
        ]}
      />
    );
  }
}

export default withTracker(RequestOfferPersonalDataPage);
