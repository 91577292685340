import { InsuranceSetting, Variante } from 'rlv-common';
import { PaymentMethod } from '../../../../helpers/PaymentMethod';

import { FormatFunction, PossibleValues, ValuesFromServerI } from './Summary.types';

// -----------------------------
// ##### API FUNCTIONS #####
// -----------------------------
/**
 * This function pass over the @param objectValues and retrieve only needed values taking
 * into account @param needed
 */
export const filterNeededValues = <T, K>(objectValues: T, needed: K) => {
  const valuesToFind: string[] = Object.values(needed);

  return Object.fromEntries(
    Object.entries(objectValues).filter(([key]) => valuesToFind.includes(key)),
  );
};

export const applyFormat = <T, S, W>(
  valuesFromServer: any,
  UIProperties: T,
  ServerProperties: S,
  formatConfig: W,
): Array<[string, string]> =>
  Object.entries(ServerProperties).map(([key, propertyServerName]) => {
    const valueDisplayedInTable = UIProperties[key];
    const formatedValueFromServer = formatConfig[propertyServerName]
      ? formatConfig[propertyServerName](valuesFromServer[propertyServerName])
      : valuesFromServer[propertyServerName];

    return [valueDisplayedInTable, formatedValueFromServer];
  });

export const convertPossibleValuesToMap = (
  possibleValues: PossibleValues,
): Map<ValuesFromServerI, string> => {
  const mapValues = new Map();

  possibleValues!.forEach(({ key, text }) => {
    mapValues.set(key, text);
  });

  return mapValues;
};

export const mapAdditionalInsuranceSettings = (settings: InsuranceSetting[], value: string) => {
  if (!settings) {
    return '';
  }

  const isActive: boolean = settings.filter(setting => setting.insuranceSettingKey === value)[0]
    .insuranceSettingValue;
  return isActive ? 'Ja' : 'Nein';
};

export const getVarianteAmount = (
  varianten: Variante[] | undefined,
  amountToRetrieve: keyof Variante,
  paymentMethod: string,
) => {
  if (!varianten || !varianten.length) {
    return '';
  }
  const varianteSelected = varianten.filter(variante => variante.selected)[0];

  return `${
    varianteSelected[amountToRetrieve]
      ? varianteSelected[amountToRetrieve]!.toLocaleString('de-DE')
      : 'XX,XX'
  } € ${PaymentMethod[paymentMethod]}`;
};

export const filterEmptyValues = <T>(data: T[]) => data.filter((value: T) => value[1]);

// TODO: could be mixed with the previous one
export const filterValuesByConditions = (data: any[], condition: (val: any) => any) =>
  data.filter(val => condition(val));

// ----------------------------
// ##### GLOBAL FUNCTIONS #####
// ----------------------------
export const formatDate: FormatFunction = (valueFromServer: string): string => {
  if (!valueFromServer) {
    return '';
  }
  const regex = /^(\d{4})-(\d{1,2})-(\d{1,2})$/;
  const isValidFormat = regex.test(valueFromServer);

  if (!isValidFormat) {
    return valueFromServer;
  }

  const [year, month, day] = valueFromServer.split('-');

  const convert = (val: string) => (val.length === 2 ? val : `0${val}`);

  return `${convert(day)}.${convert(month)}.${year}`;
};

export const formatCurrency: FormatFunction = (num: number, decimals: number = 2): string => {
  if (!num) {
    return '';
  }
  return `${num.toLocaleString('de-DE', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })} €`;
};

export const formatServerValueIntoUIValue =
  (
    conversionValues: Map<ValuesFromServerI, string> = new Map([
      [true, 'Ja'],
      [false, 'Nein'],
    ]),
  ) =>
  (val: ValuesFromServerI) =>
    conversionValues.get(val) || String(val);

export const formatValueByAddingSuffix = (suffix?: string) => (val: ValuesFromServerI) =>
  suffix ? `${val} ${suffix}` : String(val);

export const formatValueByAddingPrefix = (prefix?: string) => (val: ValuesFromServerI) =>
  prefix ? `${prefix} ${val}` : String(val);

export const formatToCapitalize = (val: string) => {
  if (!val) {
    return '';
  }
  return val.charAt(0).toUpperCase() + val.slice(1);
};

export const removeNumbersFromLabels = (text: string): string => {
  const regex = /^\d+. |\d+.[a-z]\) /g;

  return text.replace(regex, '');
};
