import Button from '@eg/elements/Button';
import Modal from '@eg/elements/components/Modal';
import React, { FC } from 'react';
import { getAgentPhone, getIsTiedAgent, getOeNumber } from '../../helpers/modeConfig';
import './ErrorModal.css';

export interface ErrorModalProps {
  businessId: string;
  open: boolean;
  showContactFormBtn: boolean;
  onClick: () => void;
}

export const ErrorModal: FC<ErrorModalProps> = ({
  businessId,
  open,
  showContactFormBtn,
  onClick,
}) => (
  <Modal open={open}>
    <p>
      <b>Ups, da ist wohl etwas schief gegangen!</b>
      <br />
      Ihre Aktion konnte nicht ausgeführt werden. Bitte stellen Sie sicher, dass eine
      Internetverbindung besteht. Versuchen Sie es dann noch einmal oder nutzen Sie unseren
      telefonischen Kontakt.
    </p>
    <br />
    {getIsTiedAgent() && getAgentPhone() && (
      <>
        <b>Bitte wenden Sie sich an Ihren Ansprechpartner: {getAgentPhone()}</b>
        <br />
        <br />
      </>
    )}
    <Button onClick={onClick}>Erneut versuchen</Button>
    {getIsTiedAgent() && showContactFormBtn && (
      <Button className="error-modal-secondary-button" variant="primary">
        <a
          className="error-modal-anchor"
          href={`https://www.ergo.de/de/Resources/Forms/Kontakt/Kontakt?adh_oenr=${getOeNumber()}`}
          rel="noreferrer"
          target="_blank"
        >
          Kontaktformular
        </a>
      </Button>
    )}
    <div className="error-modal-business-id">{businessId}</div>
  </Modal>
);
