import * as React from 'react';
import { Beitragsvariante } from 'rlv-common';
import { OfferType, OrderResponse, ResponseCodes } from 'rlv-common/types';
import { isDivergingInsuredPerson } from '../../Helper';
import { pdeLoaded } from '../../helpers/externalScripts';
import { getGenre } from '../../helpers/genreHelper';
import { isOnline } from '../../helpers/isOnline';
import { getNettoBeitragForVariant } from '../../helpers/variant.utils';
import { ViewName } from '../../helpers/ViewName';
import { isKeweEmail } from '../../helpers/kewe';
import { configuration } from '../../index';
import RequestOfferPage, {
  RequestOfferPageData,
} from '../../pages/requestOfferPersonalData/RequestOfferPersonalDataPage';
import {
  getAngebot,
  getNationalities,
  makeOffer,
  updateRequestOfferPageData,
} from '../../services/api';
import { fetchTrackingDataAfterFeePage } from '../../tracking/trackingFormating';
import { PagePercentage } from '../../types/PagePercentage';
import { StateDefinition } from '../StateMachine';
import { NavigationAction, StateName } from '../StateMachineTypes';
import { Storage } from '../../helpers/Storage';
import { SessionStorageKeys } from '../../types/SessionStorage';

export const requestOfferPersonalDataPage: StateDefinition<RequestOfferPageData> = {
  name: StateName.REQUEST_OFFER_PAGE,
  percentage: PagePercentage.PERSONAL_DATA_PAGE,
  trackingViewName: ViewName.PERSONENDATEN_ANGEBOT,
  validInboundStates: [
    StateName.FEE_BEFORE_DUW_PAGE,
    StateName.SUBSCRIPTIONS,
    StateName.FEEDBACK_PAGE,
  ],
  transitions: [
    {
      test: action => action === NavigationAction.NEXT && isOnline(),
      newState: StateName.SUBSCRIPTIONS,
    },
    {
      test: action =>
        [
          NavigationAction.NEXT,
          NavigationAction.OFFER_WRITTEN_POSTAL,
          NavigationAction.OFFER_DIRECT_ONLINE,
          NavigationAction.OFFER_WRITTEN_EMAIL,
        ].includes(action) && !isOnline(),
      newState: StateName.FEEDBACK_PAGE,
    },
    {
      test: (action, data) => {
        const backAction =
          action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK;
        return backAction;
      },
      newState: StateName.FEE_BEFORE_DUW_PAGE,
    },
  ],
  onEnter: async (transitionDetails, inputData) => {
    const response = await getAngebot(inputData.businessId);
    const nationalities = await getNationalities();

    let variante: Beitragsvariante;
    if (response.feeCalculationModel) {
      variante = response.feeCalculationModel.variante || Beitragsvariante.KOMFORT;
    }
    const variantenInformation = response.varianten.find(element => element.variante === variante);

    const fetchedTrackingData = fetchTrackingDataAfterFeePage(response) || {};

    return {
      userInput: {
        ...response.requestOfferPersonalDataModel,
        divergingInsuredPerson: isDivergingInsuredPerson(),
        nationalities,
        showMeldung: false,
        priceNetto: getNettoBeitragForVariant(variantenInformation),
      },
      valueRanges: response.valueRanges,
      fetchedTrackingData,
    };
  },
  onExit: async (transitionDetails, inputData) => {
    await updateRequestOfferPageData(inputData.businessId, inputData.userInput);
    if (
      transitionDetails.targetStateName &&
      [StateName.REQUEST_OFFER_FEEDBACK_PAGE, StateName.FEEDBACK_PAGE].includes(
        transitionDetails.targetStateName,
      )
    ) {
      let offerType;
      switch (transitionDetails.action) {
        case NavigationAction.OFFER_WRITTEN_POSTAL:
          offerType = OfferType.WRITTEN_POSTAL;
          break;
        case NavigationAction.OFFER_WRITTEN_EMAIL:
          offerType = OfferType.WRITTEN_EMAIL;
          break;
        default:
          offerType = OfferType.DIRECT_ONLINE;
          break;
      }
      Storage.writeItem(SessionStorageKeys.OFFER_TYPE, offerType);

      const response: OrderResponse = await makeOffer(
        inputData.businessId,
        inputData.userInput.oeNumber,
        inputData.userInput.vn.pdeId,
        inputData.userInput.vp.pdeId,
        offerType,
      );
      if (response.status === ResponseCodes.PRECONDITION_FAILED && response.showMeldung) {
        return { skipTransition: true, payload: { showMeldung: response.showMeldung } };
      }
      if (response.status !== ResponseCodes.SUCCESS) {
        throw new Error('Internal server error');
      }
    }
    return {};
  },
  render: (inputData, handleAction, updateApp, onError, updateDTMTracking) => {
    const { userInput, businessId, valueRanges } = inputData;
    const keweEmail = isKeweEmail(userInput.vn.email, userInput.vn.keweConsent?.emailStatus);

    return (
      <RequestOfferPage
        configuration={configuration}
        scriptLoaded={pdeLoaded}
        valueRanges={valueRanges}
        businessId={businessId}
        storeState={{
          ...userInput,
          vn: {
            ...userInput.vn,
            ...(!userInput.divergingInsuredPerson && { geschlecht: getGenre() }),
            einverstaendnisEmailWerbung: keweEmail,
          },
          vp: {
            ...userInput.vp,
            ...(userInput.divergingInsuredPerson && { geschlecht: getGenre() }),
          },
          update: values => updateApp(values),
          oeNumber: userInput.oeNumber,
          pageExitNotAllowed: userInput.pageExitNotAllowed,
        }}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
        onError={onError}
      />
    );
  },
};
