import { KeweConsentResult } from 'rlv-common';
import { SessionStorageKeys } from '../types/SessionStorage';
import { Storage } from './Storage';
import { getKeweEnabled } from './modeConfig';

export const isOnline = () => Storage.readItem(SessionStorageKeys.IS_ONLINE) === 'true';

export const isFlowDirectabschluss = () => isOnline();

export const isKeweEmail = (
  email: string | undefined,
  emailStatus: KeweConsentResult | undefined,
) => {
  if (!getKeweEnabled()) {
    return false;
  } else if (!Boolean(email)) {
    return true;
  } else {
    return emailStatus === KeweConsentResult.CONSENT_PROVIDED;
  }
};
