import * as React from 'react';
import {
  EntitledPerson,
  Geschlecht,
  Honorific,
  RequestOfferPerson,
  ValueRanges,
  Variante,
} from 'rlv-common';
import { Storage } from '../../helpers/Storage';
import { getVarianteFromPossibleValues } from '../../helpers/variant.utils';
import {
  BankDetailsProperties,
  BankDetailsUIOptions,
  BeneficiaryUIOptions,
  FormatFunction,
  HedgingNeedsProperties,
  HedgingNeedsUIOptions,
  MapBeneficiariesValues,
  MapCheckoutValues,
  PersonalDataOfTheInsuredPersonProperties,
  PersonalDataOfTheInsuredPersonUIOptions,
  PersonalDataOfThePolicyHolderOfflineProperties,
  PersonalDataOfThePolicyHolderOnlineProperties,
  PersonalDataOfThePolicyHolderOnlineUIOptions,
  RiskAssessmentProperties,
  RiskAssessmentUIOptions,
  TariffAndContributionProperties,
  TariffAndContributionUIOptions,
} from '../../pages/legalQuestions/forms/Summary/Summary.types';
import {
  applyFormat,
  convertPossibleValuesToMap,
  filterEmptyValues,
  filterNeededValues,
  filterValuesByConditions,
  formatCurrency,
  formatDate,
  formatServerValueIntoUIValue,
  formatValueByAddingPrefix,
  formatValueByAddingSuffix,
  getVarianteAmount,
  mapAdditionalInsuranceSettings,
} from '../../pages/legalQuestions/forms/Summary/Summary.utils';
import { CheckOutPageData } from '../../pages/legalQuestions/legalQuestionsPageContainer';
import { SessionStorageKeys } from '../../types/SessionStorage';

export const useGlobalAccordionData = (
  checkoutData: CheckOutPageData,
  valueRanges: ValueRanges,
  varianten?: Variante[],
) => {
  const tariffAndContributionValues = React.useMemo<
    Array<[TariffAndContributionUIOptions, string]>
  >(() => mapTariffAndContribution(checkoutData, valueRanges, varianten), [checkoutData]);
  const hedgingNeedsValues = React.useMemo<Array<[HedgingNeedsUIOptions, string]>>(
    () => mapHedgingNeeds(checkoutData, valueRanges),
    [checkoutData],
  );
  const riskAssessmentValues = React.useMemo<Array<[RiskAssessmentUIOptions, string]>>(
    () => mapRiskAssessment(checkoutData, valueRanges),
    [checkoutData],
  );
  const personalDataOfThePolicyHolderOnlineValues = React.useMemo<
    Array<[PersonalDataOfThePolicyHolderOnlineUIOptions, string]>
  >(() => mapPersonalDataOfThePolicyHolderOnline(checkoutData, valueRanges), [checkoutData]);
  const personalDataOfThePolicyHolderOfflineValues = React.useMemo<
    Array<[PersonalDataOfThePolicyHolderOnlineUIOptions, string]>
  >(() => mapPersonalDataOfThePolicyHolderOffline(checkoutData, valueRanges), [checkoutData]);
  const personalDataOfTheInsuredPersonValues = React.useMemo<
    Array<[PersonalDataOfTheInsuredPersonUIOptions, string]>
  >(() => mapPersonalDataOfTheInsuredPerson(checkoutData, valueRanges), [checkoutData]);
  const bankDetailsValues = React.useMemo<Array<[BankDetailsUIOptions, string]>>(
    () => mapBankDetails(checkoutData, valueRanges),
    [checkoutData],
  );
  const beneficiaryValues = React.useMemo<Array<Array<[BeneficiaryUIOptions, string]>>>(
    () => mapBeneficiary(checkoutData, valueRanges),
    [checkoutData],
  );

  return {
    tariffAndContributionValues,
    hedgingNeedsValues,
    riskAssessmentValues,
    personalDataOfTheInsuredPersonValues,
    personalDataOfThePolicyHolderOfflineValues,
    personalDataOfThePolicyHolderOnlineValues,
    bankDetailsValues,
    beneficiaryValues,
  };
};

const mapGender = (gender?: Geschlecht) =>
  gender === Geschlecht.WEIBLICH ? Honorific.FEMALE_ADULT : Honorific.MALE_ADULT;

/**
 * -------------------
 * ##### MAPPERS #####
 * -------------------
 * These mappers functions manage the data that should be displayed in each section of the Summary page.
 * They look pretty similar, and is tempting to simplify them by using a common "mapData" function,
 * but each section have edge cases and we are no sure yet about how we should treat each piece of data on it.
 * So lets wait until business response to simplify this.
 */

// ***** Tarif und Beitrag ( Tariff and Contribution ) *****
const mapTariffAndContribution: MapCheckoutValues = (
  checkoutData,
  valueRanges,
  varianten,
): Array<[string, string]> => {
  const formatToApply: { [key in TariffAndContributionProperties]?: FormatFunction } = {
    [TariffAndContributionProperties.TARIF]: tarif =>
      getVarianteFromPossibleValues(tarif, valueRanges?.tariffType.possibleValues),
    [TariffAndContributionProperties.DYNAMIK]: () =>
      mapAdditionalInsuranceSettings(checkoutData.additionalInsuranceSettings, 'beitragsdynamik'),
    [TariffAndContributionProperties.SAFETY_PLUS]: () =>
      mapAdditionalInsuranceSettings(checkoutData.additionalInsuranceSettings, 'sicherheitplus'),
    [TariffAndContributionProperties.GROSS_CONTRIBUTION]: () =>
      getVarianteAmount(varianten, 'bruttobeitragNachZahlweise', checkoutData.paymentMethod),
    [TariffAndContributionProperties.PAYMENT_CONTRIBUTION]: () =>
      getVarianteAmount(varianten, 'nettobeitragNachZahlweise', checkoutData.paymentMethod),
  };

  const tariffAndContributionSectionValues = filterNeededValues<
    CheckOutPageData,
    typeof TariffAndContributionProperties
  >(checkoutData, TariffAndContributionProperties);

  const data: Array<[string, string]> = applyFormat<
    typeof TariffAndContributionUIOptions,
    typeof TariffAndContributionProperties,
    any
  >(
    tariffAndContributionSectionValues,
    TariffAndContributionUIOptions,
    TariffAndContributionProperties,
    formatToApply,
  );

  return filterEmptyValues<[string, string]>(data);
};

// ***** Absicherungsbedarf ( Hedging Needs ) *****
// TODO: Should be tested
const mapHedgingNeeds: MapCheckoutValues = (checkoutData): Array<[string, string]> => {
  const formatToApply: { [key in HedgingNeedsProperties]?: FormatFunction } = {
    [HedgingNeedsProperties.INSURANCE_HISTORY]: formatServerValueIntoUIValue(
      new Map([
        ['KONSTANT', 'konstant'],
        ['LINEAR_FALLEND', 'fallend'],
      ]),
    ),
    [HedgingNeedsProperties.SUM_INSURED]: num => formatCurrency(num, 0),
    [HedgingNeedsProperties.SUM_INSURED_FIRST_YEAR]: num => formatCurrency(num, 0),
    [HedgingNeedsProperties.SUM_INSURED_LAST_YEAR]: num => formatCurrency(num, 0),
    [HedgingNeedsProperties.START_OF_INSURANCE]: formatDate,
    [HedgingNeedsProperties.END_OF_INSURANCE]: formatDate,
    [HedgingNeedsProperties.INSURANCE_TERM]: formatValueByAddingSuffix('Jahre'),
  };

  const hedgingNeedsSectionValues = filterNeededValues<
    CheckOutPageData,
    typeof HedgingNeedsProperties
  >(checkoutData, HedgingNeedsProperties);

  const data: Array<[string, string]> = applyFormat<
    typeof HedgingNeedsUIOptions,
    typeof HedgingNeedsProperties,
    any
  >(hedgingNeedsSectionValues, HedgingNeedsUIOptions, HedgingNeedsProperties, formatToApply);

  const sumValueToHide =
    checkoutData.need === 'KONSTANT' ? 'Versicherungssumme (1. Jahr)' : 'Versicherungssumme';

  return filterEmptyValues(
    filterValuesByConditions(data, (val: [string, string]) => val[0] !== sumValueToHide),
  );
};

// ***** Risikoeinschätzung ( Risk Assessment ) *****
// TODO: Should be tested
const mapRiskAssessment: MapCheckoutValues = (
  checkoutData,
  valueRanges,
): Array<[string, string]> => {
  const formatToApply: { [key in RiskAssessmentProperties]?: FormatFunction } = {
    [RiskAssessmentProperties.PROFESSION_PRACTISED]: formatServerValueIntoUIValue(
      convertPossibleValuesToMap(valueRanges!.employments.possibleValues),
    ),
    [RiskAssessmentProperties.SMOKER]: formatServerValueIntoUIValue(
      convertPossibleValuesToMap(valueRanges!.smoker.possibleValues),
    ),
    [RiskAssessmentProperties.GENDER]: () =>
      Storage.readItem(SessionStorageKeys.GENRE) === '1' ? 'Frau' : 'Mann',
    [RiskAssessmentProperties.WEIGHT]: formatValueByAddingSuffix('kg'),
    [RiskAssessmentProperties.SIZE]: formatValueByAddingSuffix('cm'),
    [RiskAssessmentProperties.MOTORCYCLIST]: formatServerValueIntoUIValue(),
    [RiskAssessmentProperties.OWNER_OCCUPIED_RESIDENTIAL_PROPERTY]: formatServerValueIntoUIValue(),
  };

  const riskAssessmentSectionValues = filterNeededValues<
    CheckOutPageData,
    typeof RiskAssessmentProperties
  >(checkoutData, RiskAssessmentProperties);

  const data: Array<[string, string]> = applyFormat<
    typeof RiskAssessmentUIOptions,
    typeof RiskAssessmentProperties,
    any
  >(riskAssessmentSectionValues, RiskAssessmentUIOptions, RiskAssessmentProperties, formatToApply);

  return filterEmptyValues<[string, string]>(data);
};

// ***** Persönliche Daten des Versicherungsnehmers ( Personal Data of the Policy Holder -- ONLINE ) *****
// TODO: Should be tested
const mapPersonalDataOfThePolicyHolderOnline: MapCheckoutValues = (
  checkoutData: CheckOutPageData,
): Array<[string, string]> => {
  const formatToApply: { [key in PersonalDataOfThePolicyHolderOnlineProperties]?: FormatFunction } =
    {
      [PersonalDataOfThePolicyHolderOnlineProperties.SALUTATION]: () =>
        mapGender(checkoutData.geschlecht),
      [PersonalDataOfThePolicyHolderOnlineProperties.STREET_HOUSE_NUMBER]: () =>
        checkoutData.adresse
          ? `${checkoutData.adresse?.strasse} ${checkoutData.adresse?.hausnummer}`
          : '-',
      [PersonalDataOfThePolicyHolderOnlineProperties.POSTCODE_PLACE_OF_RESIDENCE]: () =>
        checkoutData.adresse ? `${checkoutData.adresse?.plz} ${checkoutData.adresse?.ort}` : '-',
      [PersonalDataOfThePolicyHolderOnlineProperties.DATE_OF_BIRTH]: formatDate,
      [PersonalDataOfThePolicyHolderOnlineProperties.TELEPHONE_NUMBER]: formatValueByAddingPrefix(
        checkoutData.vorwahl,
      ),
    };

  const personalDataOfThePolicyHolderOnlineValues = filterNeededValues<
    CheckOutPageData,
    typeof PersonalDataOfThePolicyHolderOnlineProperties
  >(checkoutData, PersonalDataOfThePolicyHolderOnlineProperties);

  const somethingToWorkWith = {
    ...personalDataOfThePolicyHolderOnlineValues,
    ...checkoutData.adresse,
  };

  const data: Array<[string, string]> = applyFormat<
    typeof PersonalDataOfThePolicyHolderOnlineUIOptions,
    typeof PersonalDataOfThePolicyHolderOnlineProperties,
    any
  >(
    somethingToWorkWith,
    PersonalDataOfThePolicyHolderOnlineUIOptions,
    PersonalDataOfThePolicyHolderOnlineProperties,
    formatToApply,
  );

  return filterEmptyValues<[string, string]>(data);
};

// ***** Persönliche Daten des Versicherungsnehmers ( Personal Data of the Policy Holder -- OFFLINE ) *****
// TODO: Should be tested
const mapPersonalDataOfThePolicyHolderOffline: MapCheckoutValues = (
  checkoutData: CheckOutPageData,
): Array<[string, string]> => {
  const formatToApply: {
    [key in PersonalDataOfThePolicyHolderOfflineProperties]?: FormatFunction;
  } = {
    [PersonalDataOfThePolicyHolderOfflineProperties.SALUTATION]: () =>
      mapGender(checkoutData.geschlecht),
    [PersonalDataOfThePolicyHolderOfflineProperties.DATE_OF_BIRTH]: formatDate,
    [PersonalDataOfThePolicyHolderOfflineProperties.STREET_HOUSE_NUMBER]: () =>
      checkoutData.adresse
        ? `${checkoutData.adresse.strasse} ${checkoutData.adresse.hausnummer}`
        : '-',
    [PersonalDataOfThePolicyHolderOfflineProperties.POSTCODE_PLACE_OF_RESIDENCE]: () =>
      checkoutData.adresse ? `${checkoutData.adresse.plz} ${checkoutData.adresse.ort}` : '-',
    [PersonalDataOfThePolicyHolderOfflineProperties.TELEPHONE_NUMBER]: formatValueByAddingPrefix(
      checkoutData.vorwahl,
    ),
  };

  const personalDataOfThePolicyHolderOnlineValues = filterNeededValues<
    CheckOutPageData,
    typeof PersonalDataOfThePolicyHolderOnlineProperties
  >(checkoutData, PersonalDataOfThePolicyHolderOnlineProperties);

  const somethingToWorkWith = { ...personalDataOfThePolicyHolderOnlineValues };

  const data: Array<[string, string]> = applyFormat<
    typeof PersonalDataOfThePolicyHolderOnlineUIOptions,
    typeof PersonalDataOfThePolicyHolderOnlineProperties,
    any
  >(
    somethingToWorkWith,
    PersonalDataOfThePolicyHolderOnlineUIOptions,
    PersonalDataOfThePolicyHolderOnlineProperties,
    formatToApply,
  );

  return filterEmptyValues<[string, string]>(data);
};

// ***** Persönliche Daten der versicherten Person - optional ( Personal Data of the Insured Person ) *****
// TODO: Should be tested
const mapPersonalDataOfTheInsuredPerson: MapCheckoutValues = (
  checkoutData: CheckOutPageData,
): Array<[string, string]> => {
  if (!checkoutData.vp) {
    return [];
  }

  const formatToApply: { [key in PersonalDataOfTheInsuredPersonProperties]?: FormatFunction } = {
    [PersonalDataOfTheInsuredPersonProperties.SALUTATION]: () =>
      mapGender(checkoutData.vp?.geschlecht),
    [PersonalDataOfTheInsuredPersonProperties.STREET_HOUSE_NUMBER]: () =>
      checkoutData.vp!.adresse
        ? `${checkoutData.vp?.adresse.strasse} ${checkoutData.vp?.adresse.hausnummer}`
        : '-',
    [PersonalDataOfTheInsuredPersonProperties.POSTCODE_PLACE_OF_RESIDENCE]: () =>
      checkoutData.vp!.adresse
        ? `${checkoutData.vp?.adresse?.plz} ${checkoutData.vp?.adresse?.ort}`
        : '-',
    [PersonalDataOfTheInsuredPersonProperties.DATE_OF_BIRTH]: formatDate,
  };

  let vpNationalityText;

  if (checkoutData.nationalities) {
    const vpNationalityForKey = checkoutData.nationalities.find(
      nation => nation.key === checkoutData.vp!.staatsangehoerigkeit,
    );
    vpNationalityText = vpNationalityForKey
      ? vpNationalityForKey.text
      : checkoutData.vp.staatsangehoerigkeit;
  }

  const PersonalDataOfTheInsuredPerson = filterNeededValues<
    RequestOfferPerson,
    typeof PersonalDataOfTheInsuredPersonProperties
  >(checkoutData.vp, PersonalDataOfTheInsuredPersonProperties);

  const somethingToWorkWith = { ...PersonalDataOfTheInsuredPerson, vpNationalityText };

  const data: Array<[string, string]> = applyFormat<
    typeof PersonalDataOfTheInsuredPersonUIOptions,
    typeof PersonalDataOfTheInsuredPersonProperties,
    any
  >(
    somethingToWorkWith,
    PersonalDataOfTheInsuredPersonUIOptions,
    PersonalDataOfTheInsuredPersonProperties,
    formatToApply,
  );

  return filterEmptyValues<[string, string]>(data);
};

// ***** Bankverbindung ( Bank details ) *****
// TODO: Should be tested
const mapBankDetails: MapCheckoutValues = (checkoutData): Array<[string, string]> => {
  const formatToApply: { [key in BankDetailsProperties]?: FormatFunction } = {};

  const bankDetailsSectionValues = filterNeededValues<
    CheckOutPageData,
    typeof BankDetailsProperties
  >(checkoutData, BankDetailsProperties);

  const data: Array<[string, string]> = applyFormat<
    typeof BankDetailsUIOptions,
    typeof BankDetailsProperties,
    any
  >(bankDetailsSectionValues, BankDetailsUIOptions, BankDetailsProperties, formatToApply);

  return filterEmptyValues<[string, string]>(data);
};

// ***** Bezugsberechtigte Person(en) ( Beneficiary ) *****
// TODO: Should be tested
const mapBeneficiary: MapBeneficiariesValues = (checkoutData, valueRanges) => {
  const beneficiaries = checkoutData.entitledPersons;

  const mappedValues = beneficiaries.reduce(
    (
      prevVal: Array<Array<[string, string]>>,
      beneficiary: EntitledPerson,
    ): Array<Array<[string, string]>> => {
      const mappValue: Array<Array<[string, string]>> = [
        [
          [BeneficiaryUIOptions.SALUTATION, ''],
          [BeneficiaryUIOptions.FIRST_NAME, beneficiary.person.firstName],
          [BeneficiaryUIOptions.LAST_NAME, beneficiary.person.surname],
          [
            BeneficiaryUIOptions.RELATIONSHIP,
            formatServerValueIntoUIValue(
              convertPossibleValuesToMap(valueRanges!.relationships.possibleValues),
            )(beneficiary.relationship),
          ],
          [
            BeneficiaryUIOptions.PERCENTAGE_SUBSCRIPTION_RIGHT,
            formatValueByAddingSuffix('%')(beneficiary.entitlementPercentage),
          ],
        ],
      ];
      return prevVal.concat(mappValue);
    },
    [],
  );

  return mappedValues;
};
