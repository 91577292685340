import * as React from 'react';
import { TEXT } from './KeweSectionAnnotation.const';
import './KeweSectionAnnotation.css';

export const KeweSectionAnnotation = () => (
  <div className="kewe-section-annotation">
    <span>*</span>
    <p className="kewe-clause">{TEXT.DESCRIPTION}</p>
  </div>
);
