export enum KeweConsentResult {
  CONSENT_PROVIDED = 'JA',
  CONSENT_DENIED = 'WIDERSPROCHEN',
  KEWE_CONSENT_DISABLED = 'NEIN',
}

export interface KeweConsent {
  emailStatus: KeweConsentResult;
  phoneStatus: KeweConsentResult;
}
