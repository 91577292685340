import { Card } from '@eg/elements/Card';
import * as React from 'react';
import { CorrectedAddress } from 'rlv-common';
import { mapKeweStatus } from 'src/tracking/trackingFormating';
import { KeweSectionAnnotation } from '../../components/KeweSectionAnnotation/KeweSectionAnnotation';
import { scrollToTop } from '../../Helper';
import AppLoader from '../../components/AppLoader';
import Footer from '../../components/Footer';
import { ErrorsList } from '../../components/PersonalDataPage/ErrorsList';
import { scrollToErrorByQuery } from '../../components/ScrollToError';
import { getServiceConfiguration } from '../../configuration/serviceConfiguration';
import { isOnline } from '../../helpers/isOnline';
import { createScriptError } from '../../helpers/loadingScriptErrorHelper';
import {
  actionAfterSaving,
  createVn,
  getErrorMessages,
  getIsAllPdePageDataValid,
  noActionSelected,
} from '../../helpers/personalDataPage';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { updatePersonalPageData } from '../../services/api';
import { DTMSeitenname } from '../../tracking/dtmTracking';
import { TrackingConversionTypes, TrackingElementBasic } from '../../tracking/tracking.types';
import { withTracker } from '../../tracking/withTracker';
import { Callbacks as PDECallbacks } from '../../types/external/personendatenerfassung/messaging/model/input/Callbacks';
import { PersonendatenInput } from '../../types/external/personendatenerfassung/messaging/model/input/PersonendatenInput';
import {
  FinishedPersonendatenOutput,
  PersonendatenOutput,
} from '../../types/external/personendatenerfassung/messaging/model/output/PersonendatenOutput';
import { AnfrageTyp } from '../../types/external/personendatenerfassung/messaging/model/input/AnfrageTyp';
import { getKeweEnabled } from '../../helpers/modeConfig';
import {
  PersonalDataPageProps,
  PersonalDataPageState,
  SetStateHandlerArg,
} from './PersonalDataPage.types';
import './PersonalDataPage.css';

export class PersonalDataPage
  extends React.Component<PersonalDataPageProps, PersonalDataPageState>
  implements PDECallbacks
{
  public constructor(props: PersonalDataPageProps) {
    super(props);
    this.state = {
      isLoading: false,
      actionAfterSaving: noActionSelected,
      showGlobalErrorMessages: false,
      pdeValid: !!props.storeState.pdeId,
      pdeEventRunning: false,
      isAddressInvalidInPds: false,
      enableNewPersonalDataFlow: true, // hardcoded by the app
    };
    this.setStateHandler = this.setStateHandler.bind(this);
    this.renderPDE = this.renderPDE.bind(this);
  }

  public onCorrectedAddress = (correctedAddresses: CorrectedAddress[]) => {
    correctedAddresses.forEach(correctedAddress => {
      if (correctedAddress.pdeId === this.props.storeState.pdeId) {
        this.props.storeState.update({
          adresse: {
            strasse: correctedAddress.strasse,
            hausnummer: correctedAddress.hausnummer,
            plz: correctedAddress.plz,
            ort: correctedAddress.ort,
          },
        });
      }
    });
  };

  public onInvalidAddress = (isAddressInvalid: boolean) => {
    this.setState({ isAddressInvalidInPds: isAddressInvalid });
    this.props.storeState.update({ pageExitNotAllowed: isAddressInvalid });
  };

  public onError = (e: Error) => {
    this.props.onError(e);
  };

  public onChangedPersonendaten = (output: PersonendatenOutput) => {
    this.setState({
      pdeValid: output.isAbschlussMoeglich,
      pdeEventRunning: false,
    });
    this.props.storeState.update({
      ...output.vn,
      pdeId: output.vn.id,
    });
  };

  public onFinishedPersonendaten = async (output: FinishedPersonendatenOutput) => {
    this.setState({
      isAddressCorrectedInPds: output.isAddressCorrected || false,
      isEmailOptionalValid: output.isEmailOptionalValid,
      isEmailMandatoryValid: output.isEmailMandatoryValid || false,
    });

    if (!this.state.enableNewPersonalDataFlow) {
      await updatePersonalPageData(this.props.businessId, this.props.storeState);
      this.closePde();

      this.props.handleAction(NavigationAction.NEXT);
    } else if (!output.isAddressCorrected) {
      this.props.handleAction(NavigationAction.NEXT);
    }
  };

  private closePde() {
    this.setState({
      showGlobalErrorMessages: false,
      pdeEventRunning: false,
    });
    document.dispatchEvent(new CustomEvent('unmountPersonendaten', {}));
    scrollToErrorByQuery('[data-component-id="vp-view-card"]');
  }

  public async componentDidMount() {
    scrollToTop();
    await this.initPde();
    this.props.updateDTMTracking({ seitenName: DTMSeitenname.PERSONENDATEN });
    this.props.tracker.trackPageLoad({
      conversionType: TrackingConversionTypes.SALE_FUNNEL_PERSONAL_DATA,
    });
  }

  public componentWillUnmount() {
    document.dispatchEvent(new CustomEvent('unmountPersonendaten', {}));
  }

  public setStateHandler(newState: SetStateHandlerArg, callback?: () => void) {
    this.setState({ ...this.state, ...newState }, callback ? () => callback() : undefined);
  }

  public render() {
    const errorMessages = getErrorMessages(this.state.pdeValid, this.state.isEmailMandatoryValid);
    const isAllPdePageDataValid = getIsAllPdePageDataValid(
      this.state.pdeValid,
      this.state.isEmailMandatoryValid,
    );

    return (
      <div className="personal-data-page" data-component-id="PersonalDataPage">
        <AppLoader show={this.state.isLoading} viewport="relative">
          <h3>Personendatenerfassung</h3>
          <br />
          <div>
            <Card label="Versicherte Person" accentColor="#737373">
              <div id="root-personendaten" />
            </Card>

            <div id="annotation-text" />
          </div>
          {!isAllPdePageDataValid && this.state.showGlobalErrorMessages && (
            <ErrorsList errorMessages={errorMessages} />
          )}
          <Footer
            showPrevious={true}
            showNext={true}
            labelNext={'weiter'}
            handleAction={this.props.handleAction}
            handleBack={this.setHandleBack}
            handleNext={this.setHandleNext}
            infoSection={!this.state.isKeweSectionHidden ? <KeweSectionAnnotation /> : undefined}
          />
        </AppLoader>
      </div>
    );
  }

  private readonly setHandleBack = () => {
    this.setState({
      actionAfterSaving: () =>
        actionAfterSaving(
          NavigationAction.BACK,
          this.state.pdeValid,
          this.state.isEmailMandatoryValid,
          this.props.handleAction,
          this.setStateHandler,
        ),
      showGlobalErrorMessages: true,
    });

    document.dispatchEvent(new CustomEvent('nextClicked', { detail: 'DIREKTABSCHLUSS' }));

    if (isOnline()) {
      this.props.handleAction(NavigationAction.BACK);
    } else {
      this.props.handleAction(NavigationAction.DIRECT_JUMP_FEE_PAGE_BEFORE_DUW);
    }
  };

  private readonly setHandleNext = () => {
    const { tracker } = this.props;
    tracker.updateUserContactInfo(this.props.storeState);

    const keweConsent = this.props.storeState.keweConsent;
    tracker.updateTariffOptions(mapKeweStatus(keweConsent));

    tracker.trackClickEvent({
      clickedElement: TrackingElementBasic.BUTTON_NEXT,
    });

    this.setState({
      actionAfterSaving: () =>
        actionAfterSaving(
          NavigationAction.BACK,
          this.state.pdeValid,
          this.state.isEmailMandatoryValid,
          this.props.handleAction,
          this.setStateHandler,
        ),
      showGlobalErrorMessages: false,
    });

    if (
      !getIsAllPdePageDataValid(this.state.pdeValid, this.state.isEmailMandatoryValid) &&
      this.state.isAddressInvalidInPds
    ) {
      scrollToErrorByQuery('[role="alert"]');
      return;
    }

    if (!isOnline()) {
      this.props.handleAction(NavigationAction.DIRECT_JUMP_SUMMARY);
      return;
    } // fixme: PABLO C. check if this is necessary, could be possible that if IS_ONLINE===false this component is not mounted

    document.dispatchEvent(new CustomEvent('nextClicked', { detail: AnfrageTyp.DIREKTABSCHLUSS }));
  };

  private async initPde() {
    await this.props.scriptLoaded
      .then(() => {
        if (!this.props.configuration || !this.props.configuration.theme) {
          throw new Error('The PersonalDataPage needs at least a theme! Check your configuration!');
        }

        this.renderPDE();
      })
      .catch((error: Event) => {
        this.props.onError(createScriptError(error));
      });
  }

  public renderPDE() {
    document.dispatchEvent(
      new CustomEvent('renderPersonendaten', {
        detail: {
          callbacks: {
            onChangedPersonendaten: this.onChangedPersonendaten,
            onError: this.onError,
            onFinishedPersonendaten: this.onFinishedPersonendaten,
            onCorrectedAddress: this.onCorrectedAddress,
            onInvalidAddress: this.onInvalidAddress,
            onKeweSectionVisibilityChange: (isHidden: boolean) =>
              this.setState({ isKeweSectionHidden: isHidden }),
          },
          theme: this.props.configuration.theme,
          apiRootUrl: getServiceConfiguration().pde.baseUrl,
          creditor: {
            name: '',
          },
          angebotsId: this.props.businessId,
          vn: createVn(this.props.storeState, this.props.duwGeschlecht),
          disableEwe: true,
          enableNewPersonalDataFlow: this.state.enableNewPersonalDataFlow, // hardcoded by app
          enableKEweConsent: getKeweEnabled(), // from AEM
          addressValidation: true,
        } as PersonendatenInput,
      }),
    );
  }
}

export default withTracker(PersonalDataPage);
