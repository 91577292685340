import * as React from 'react';
import { pdeLoaded } from '../../../helpers/externalScripts';
import { getGenre } from '../../../helpers/genreHelper';
import { ViewName } from '../../../helpers/ViewName';
import { isKeweEmail } from '../../../helpers/kewe';
import { configuration } from '../../../index';
import PersonalDataPage from '../../../pages/personalData/PersonalDataPage';
import { PersonalPageData } from '../../../pages/personalData/PersonalDataPage.types';
import { getAngebot, getNationalities, updatePersonalPageData } from '../../../services/api';
import { fetchTrackingDataAfterFeePage } from '../../../tracking/trackingFormating';
import { PagePercentage } from '../../../types/PagePercentage';
import { FetchedTrackingData, StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const personalDataPage: StateDefinition<PersonalPageData> = {
  name: StateName.PERSONAL_DATA_PAGE,
  percentage: PagePercentage.PERSONAL_DATA_PAGE,
  trackingViewName: ViewName.PERSONENDATEN,
  validInboundStates: [
    StateName.FEE_BEFORE_DUW_PAGE,
    StateName.DUW_ASSESSMENT_PAGE,
    StateName.SUBSCRIPTIONS,
    StateName.LEGAL_QUESTIONS_MONEY_LAUNDERING_PAGE,
    StateName.LEGAL_QUESTIONS_SUMMARY_PAGE,
  ],
  transitions: [
    // Pagetransitions
    {
      test: action => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
      newState: StateName.DUW_ASSESSMENT_PAGE,
    },
    {
      test: action => action === NavigationAction.NEXT,
      newState: StateName.SUBSCRIPTIONS,
    },
    {
      test: action => action === NavigationAction.DIRECT_JUMP_FEE_PAGE_BEFORE_DUW,
      newState: StateName.FEE_BEFORE_DUW_PAGE,
    },
    {
      test: action => action === NavigationAction.DIRECT_JUMP_SUMMARY,
      newState: StateName.LEGAL_QUESTIONS_SUMMARY_PAGE,
    },
  ],
  onEnter: async (_, inputData) => {
    const response = await getAngebot(inputData.businessId);
    const nationalities = await getNationalities();

    const fetchedTrackingData: FetchedTrackingData = fetchTrackingDataAfterFeePage(response) || {};
    return {
      userInput: {
        ...response.personalDataModel,
        nationalities,
        duwGeschlecht: getGenre(),
        geschlecht: getGenre(),
      },
      valueRanges: response.valueRanges,
      fetchedTrackingData,
    };
  },
  onExit: async (_, inputData) => {
    await updatePersonalPageData(inputData.businessId, inputData.userInput);

    return {};
  },
  render: (inputData, handleAction, updateApp, onError, updateDTMTracking) => {
    const { userInput, businessId, valueRanges } = inputData;
    const keweEmail = isKeweEmail(userInput.email, userInput.keweConsent?.emailStatus);

    return (
      <PersonalDataPage
        configuration={configuration}
        scriptLoaded={pdeLoaded}
        valueRanges={valueRanges}
        storeState={{
          pdeId: userInput.pdeId,
          role: userInput.role,
          birthdate: userInput.birthdate,
          geschlecht: userInput.geschlecht,
          vorname: userInput.vorname,
          nachname: userInput.nachname,
          staatsangehoerigkeit: userInput.staatsangehoerigkeit,
          adresse: userInput.adresse,
          geburtsort: userInput.geburtsort,
          email: userInput.email,
          einverstaendnisEmailWerbung: keweEmail,
          iban: userInput.iban,
          bic: userInput.bic,
          vorwahl: userInput.vorwahl,
          rufnummer: userInput.rufnummer,
          tagDerAbbuchung: userInput.tagDerAbbuchung,
          abbuchungsTage: userInput.abbuchungsTage,
          entitledPersons: userInput.entitledPersons,
          nationalities: userInput.nationalities,
          update: values => updateApp(values),
          pageExitNotAllowed: userInput.pageExitNotAllowed,
          keweConsent: userInput.keweConsent,
        }}
        duwGeschlecht={userInput.duwGeschlecht}
        onError={onError}
        businessId={businessId}
        handleAction={handleAction}
        updateDTMTracking={updateDTMTracking}
      />
    );
  },
};
