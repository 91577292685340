import * as React from 'react';
import { Geschlecht } from 'rlv-common';
import { Storage } from '../../../helpers/Storage';
import { ViewName } from '../../../helpers/ViewName';
import AfterSalesFeedback, {
  AfterSalesFeedbackData,
} from '../../../pages/afterSalesFeedback/AfterSalesFeedbackPage';
import { getAngebot } from '../../../services/api';
import { fetchTrackingDataFeedbackPage } from '../../../tracking/trackingFormating';
import { SessionStorageKeys } from '../../../types/SessionStorage';
import { StateDefinition } from '../../StateMachine';
import { StateName } from '../../StateMachineTypes';

export const afterSalesFeedback: StateDefinition<AfterSalesFeedbackData> = {
  name: StateName.FEEDBACK_PAGE,
  trackingViewName: ViewName.ABSCHLUSSFEEDBACK,
  validInboundStates: [
    StateName.LEGAL_QUESTIONS_IDD_DOWNLOAD_PDF_PAGE,
    StateName.LEGAL_QUESTIONS_SUMMARY_PAGE,
    StateName.REQUEST_OFFER_PAGE,
  ],
  transitions: [],
  onEnter: async (_, inputData) => {
    const response = await getAngebot(inputData.businessId);

    const fetchedTrackingData = fetchTrackingDataFeedbackPage(response);
    const isOnline = Storage.readItem(SessionStorageKeys.IS_ONLINE);
    const offerType = Storage.readItem(SessionStorageKeys.OFFER_TYPE);

    return {
      userInput: {
        ...response.feedbackModel,
        isOnline,
        offerType,
      },
      valueRanges: response.valueRanges,
      fetchedTrackingData,
    };
  },
  render: (inputData, _unused, _unused2, onError, updateDTMTracking) => {
    const userInput = inputData.userInput;
    return (
      <AfterSalesFeedback
        storeState={{
          geschlecht: userInput.geschlecht ? userInput.geschlecht : Geschlecht.MAENNLICH,
          vorname: userInput.vorname ? userInput.vorname : '',
          nachname: userInput.nachname ? userInput.nachname : '',
          variante: userInput.variante,
          inceptionDate: userInput.inceptionDate,
          priceNetto: userInput.priceNetto,
          paymentMethod: userInput.paymentMethod,
          additionalTimeWaiting: userInput.additionalTimeWaiting,
          update: _values => undefined,
          vnPdsId: userInput.vnPdsId,
          businessId: userInput.businessId,
          isOnline: userInput.isOnline,
          offerType: userInput.offerType,
        }}
        onError={onError}
        updateDTMTracking={updateDTMTracking}
      />
    );
  },
};
